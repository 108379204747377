<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="600"
    scrollable
  >
    <v-card :loading="isLoading">
      <v-card-title class="caption">
        <h3>{{$_strings.order.COMPLATED_THE_ORDER}} {{order.orderNumber}}</h3>
        <v-row>
          <v-col class="d-flex pa-0 ma-0 justify-end">
            <v-toolbar-items>
              <v-btn
                icon
                color="black"
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-4">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12" sm="3">
              {{$_strings.order.NOTE}}
              <h3 class="error--text d-inline">
                *
              </h3>
            </v-col>
            <v-col class="pb-0">
              <v-textarea
                no-resize
                outlined
                dense
                v-model="form.notes"
                rows="5"
                :counter="255"
                :rules="[(v) => rulesNotes(v, true, 255, $_strings.order.NOTE)]"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="3">
              {{$_strings.order.STATUS}}
              <h3 class="error--text d-inline">
                *
              </h3>
            </v-col>
            <v-col>
              <v-select
                dense
                outlined
                v-model="form.status"
                :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.order.STATUS)]"
                placeholder="Pilih status"
                item-text="text"
                item-value="value"
                :items="statusItems"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          class="white--text"
          @click="dialog = false"
        >
          {{$_strings.common.CANCEL}}
        </v-btn>
        <v-btn
          :loading="isLoading"
          color="primary"
          @click="completeOrder"
        >
          {{$_strings.order.COMPLETE_THE_ORDER}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { rulesNotes, getDateTimeTz } from '@/helper/commonHelpers';

export default {
  watch: {
    dialog(newVal) {
      if (!newVal) {
        this.$refs.form.reset();
        this.form.notes = '';
        this.form.status = '';
      }
    },
  },
  data() {
    return {
      isLoading: false,
      valid: false,
      dialog: false,
      rules: {
        notes: [
          // NOTES REQUIRED
          (v) => !!v || this.$_strings.messages.validation.REQUIRED(this.$_strings.order.NOTE),
          // CHARACTER LENGTH MAX 255
          (v) => (v && v.length > 255 ? 'Jumlah karakter maksimum 255' : true),
        ],
      },
      order: {},
      form: {
        notes: '',
        status: '',
        datetimeTz: this.getDateTimeTz(),
      },
      statusItems: [
        {
          text: 'Barang Diterima Utuh',
          value: 'BARANG_DITERIMA_UTUH',
        },
        {
          text: 'Barang Diterima Tidak Utuh',
          value: 'BARANG_DITERIMA_TIDAK_UTUH',
        },
        {
          text: 'Ditolak',
          value: 'DITOLAK',
        },
        {
          text: 'BA Penerimaan - Diterima Gudang',
          value: 'BA_PENERIMAAN_DITERIMA_GUDANG',
        },
      ],
    };
  },
  methods: {
    rulesNotes,
    getDateTimeTz,
    completeOrder() {
      const valid = this.$refs.form.validate();
      if (valid) this.$emit('completeOrder', this.order.id, this.form);
    },
  },
};
</script>
